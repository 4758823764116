import ForeignCard from './ForeignCard' // 外来人员，专网卡片
import KeyPointCard from './KeyPointCard' // 重点人员卡片
import KeyPonitRollingCard from './KeyPonitRollingCard' //人员详情下方滚动列表卡片
import ObjectMapPersonnelCard from './ObjectMapPersonnel' // 人员档案卡片
import Tags from './Tags' // 人员档案标签统计
import CommunityChartCard from './CommunityChartCard' //社区图表卡片
import Similar from './Similar' // 关联vid卡片
import CaptureCard from './CaptureCard' // 图库基本卡片
import WifiCard from './WifiCard' // wifi资源库卡片
import MassControlCard from './MassControlCard' // 线索卡片
import CloudCard from './CloudCard' // 云社区总览图表卡片
import LeftRightCard from './LeftRightCard' // 云社区总览列表卡片（左右结构）
import AccessControl from './AccessControl' // 门禁记录卡片
import ControlCard from './ControlCard'
import PersonTrackCard from './PersonTrackCard'
import './index.less'

const card = {
  ForeignCard,
  KeyPointCard,
  KeyPonitRollingCard,
  ObjectMapPersonnelCard,
  Tags,
  CommunityChartCard,
  Similar,
  CaptureCard,
  WifiCard,
  MassControlCard,
  CloudCard,
  LeftRightCard,
  AccessControl,
  ControlCard,
  PersonTrackCard
}

export default card
