import React from "react";

import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");
const CaptureCard = Loader.loadBusinessComponent("Card", "CaptureCard");
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");

class ForeignCard extends React.Component {
  constructor(props) {
    super(props);
  }
  handleJumPage = (id, alarmType) => {
    this.props.handleJumPage && this.props.handleJumPage(id, alarmType);
  };
  getActionNAme = (alarmType) => {
    switch(alarmType){
      case '2':
        return 'outsiderHandle'
      case '3':
        return 'eventHandle'
      case '4':
        return ''
      case '5':
        return 'privateNetHandle'
      default:
        return ''
    }
  }

  render() {
    const { data = {}, isActual, handleChangeYN, score } = this.props;
    let {
      isEffective,
      faceUrl,
      captureTime,
      alarmType,
      isHandle,
      taskName,
      deviceName,
      id,
      libName,
      address,
      height
    } = data;
    return (
      <CaptureCard
        className={isActual ? "foreign-card-real" : "foreign-card"}
        style={{ height: `${height}px!important` }}
        onClick={() => this.handleJumPage(id, alarmType)}
        score={score}
        address={address}
        hoverScale={false}
        imgUrl={faceUrl}
        relativeIcon={""}
        captureTime={captureTime}
        deviceName={deviceName}
      >
        {alarmType !== "5" ? (
          <div className="item">
            <IconFont type={"icon-S_Bar_Layer"} theme="outlined" />
            <span className="content-value" title={taskName}>
              {taskName}
            </span>
          </div>
        ) : (
          <div className="item">
            <IconFont type={"icon-S_Bar_Layer"} theme="outlined" />
            <span className="title" title={libName}>
              {libName}
            </span>
          </div>
        )}
        {!isActual && alarmType !== "5" ? isHandle == 0 ? (
            <div className="card-footer">
            <AuthComponent actionName={this.getActionNAme(alarmType)}>
              <div
                className="handle handle-no"
                onClick={handleChangeYN && handleChangeYN.bind(this, data, 0)}
              >
                <IconFont type={"icon-S_Photo_ThumbInvalid"} theme="outlined" />
                无效
              </div>
              </AuthComponent>
              <AuthComponent actionName={this.getActionNAme(alarmType)}>
              <div
                className="handle handle-yes"
                onClick={handleChangeYN && handleChangeYN.bind(this, data, 1)}
              >
                <IconFont type={"icon-S_Photo_ThumbEffective"} theme="outlined" />
                有效
              </div>
              </AuthComponent>
            </div>
          ) : (
            <div className="card-footer" style={{ height: "24px" }} /> 
          ) : ''
        }
        {(!isActual && alarmType !== "5") && isHandle == 1 && (
          <div
            className={`alam-rotate ${
              isEffective == 1 ? "alarm-rotate-yes" : "alarm-rotate-no"
            }`}
          />
        )}
      </CaptureCard>
    );
  }
}

export default ForeignCard;
