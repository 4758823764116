import React, { Component } from 'react';
import './index.less';

const EchartsReact = Loader.loadBaseComponent('EchartsReact')
const IconFont = Loader.loadBaseComponent('IconFont');
const IconTitle = Loader.loadBaseComponent('IconTitle');


class CloudCard extends Component {
  getOption = () => {
    const {
      colors = ['rgba(255, 170, 0, 1)', 'rgba(216, 220, 227, 1)'],
      data = [{ value: 1232, label: '已登记人员' }, { value: 1232, label: '未登记人员' }]
    } = this.props;
    const dataX = data.map(v => v.value);
    const option = {
      color: colors,
      series: [
        {
          type: 'pie',
          symbol: 'circle',
          radius: ['82%', '100%'],
          center: ['50%', '50%'],
          hoverAnimation: false,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: dataX
        }
      ]
    };
    return option;
  };
  render() {
    const {
      title = '社区车辆',
      total = '16867',
      icon,
      colors = ['rgba(255, 170, 0, 1)', 'rgba(216, 220, 227, 1)', 'rgba(216, 220, 227, 1)'],
      data = [{ value: 0, label: '已登记人员' }, { value: 0, label: '未登记人员' }, { value: 0, label: '未登记人员' }]
    } = this.props;
    return (
      <IconTitle title={title} icon={icon} className="cloud-echarts-card">
        <div className="card-section">
          <div className="section-left">
            <EchartsReact option={this.getOption()} style={{ height: '96px', width: '96px' }} />
            <div className="left-total">
              总数
              <div className="left-num" style={{ color: colors[0] }}>
                {(+total).toLocaleString()}
              </div>
            </div>
          </div>
          <div className="section-right">
            {data.map((v, index) => (
              <div className="left-message">
                <div className="left">
                  <span className="circle" style={{ backgroundColor: colors[index] }} />
                  {v.label}
                </div>
                <p className="num" style={{ color: colors[0] }}>
                  {(+v.value).toLocaleString()}
                </p>
              </div>
            ))}
          </div>
        </div>
      </IconTitle>
    );
  }
}

export default CloudCard;
