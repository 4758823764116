/**
 * onClick 跳转方法
 * imgUrl 图片路径
 * onFollow 设置关注
 * onRelation 设置关联 不传择不显示关联图标
 * personId 人员personId
 * aid  人员aid
 * address 地址
 * personName 人员姓名
 * lastTime 最后出现时间
 * lastAddress 最后出现地点
 * tags 标签数组
 * isFocus 是否关注
 * countDay 出现天数
 * hasAid  是否已经关联aid
 * relationShow 是否显示关联按钮
 */

import React from "react";
import { Popover } from "antd";
import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");
const ImageView = Loader.loadBusinessComponent("ImageView");

class ObjectMapPersonnelCard extends React.Component {
  constructor(props) {
    super(props);
    this.infoRef = React.createRef();
    this.dict = [
      ...Dict.map.gait,
      ...Dict.map.height,
      ...Dict.map.faceMask,
      ...Dict.map.identity,
      ...Dict.map.fatAndThin,
      ...Dict.map.aidBehavior,
      ...Dict.map.appearPlace,
      ...Dict.map.behaviorAttr,
      ...Dict.map.personnelAttr,
      ...Dict.map.aidBehaviorCode
    ];
  }
  render() {
    let {
      onClick,
      data = {},
      onFollow,
      onRelation,
      personId,
      aid,
      address,
      personName,
      imgUrl,
      lastTime,
      lastAddress,
      tags = [],
      isFocus = false,
      countDay,
      countTitle,
      configData,
      relationShow
    } = this.props;
    return (
      <div
        className="object-map-personnel-card"
        onClick={() => onClick && onClick(data)}
      >
        <div className="map-personnerl-header">
          <div className="img-box">
            <ImageView className="image" src={imgUrl} />
          </div>
          <div className="header-info">
            <div className="info-header" ref={this.infoRef}>
              <Popover
                placement="left"
                content={isFocus ? "取消关注" : "关注"}
                getPopupContainer={() => this.infoRef.current}
              >
                <IconFont
                  type={
                    isFocus ? "icon-S_Photo_FollowYes" : "icon-S_Photo_FollowNo"
                  }
                  className={isFocus ? "icon-alive" : "icon-false"}
                  theme="outlined"
                  onClick={e => onFollow && onFollow(data, e)}
                />
              </Popover>
              {relationShow && (
                <Popover
                  placement="left"
                  content={"关联"}
                  getPopupContainer={() => this.infoRef.current}
                >
                  <IconFont
                    type={"icon-S_Bar_Linked"}
                    theme="outlined"
                    className="relation-icon"
                    onClick={e => onRelation && onRelation(data, e)}
                  />
                </Popover>
              )}
            </div>
            {!aid && personId && (
              <div className="info-content">
                <p className="content-text name">
                  <IconFont type={"icon-S_Tree_People"} theme="outlined" />
                  <span className="value">{personName}</span>
                </p>
                <p className="content-text">
                  <IconFont type={"icon-S_Bar_Map"} theme="outlined" />
                  <span className="value" title={address}>
                    {address}
                  </span>
                </p>
              </div>
            )}
            {aid && (
              <div className="info-content">
                <p className="content-text">
                  <IconFont type={"icon-S_View_Clustering"} theme="outlined" />
                  <span className="value" title={aid}>
                    {aid}
                  </span>
                </p>
              </div>
            )}
            {(aid || relationShow) && (
              <div className="info-content">
                <div className="title">最近出现：</div>
                <p className="content-text">
                  <IconFont type={"icon-S_Bar_Add"} theme="outlined" />
                  <span className="value" title={lastAddress || "暂无"}>
                    {lastAddress || "暂无"}
                  </span>
                </p>
                <p className="content-text">
                  <IconFont type={"icon-M_Bar_Clock"} theme="outlined" />
                  <span className="value">
                    {lastTime ? Utils.formatTimeStamp(+lastTime) : "暂无"}
                  </span>
                </p>
              </div>
            )}
            {countDay && (
              <div className="info-content">
                <div className="title">{`${countTitle ||
                  `${configData || 30}天内出现`}：`}</div>
                <p className="content-text">
                  <IconFont
                    type={"icon-S_View_SelectChoosed"}
                    theme="outlined"
                  />
                  <span className="value">{`${countDay}天`}</span>
                </p>
              </div>
            )}
          </div>
        </div>
        {tags.length ? (
          <div className="map-personnerl-footer">
            {tags
              .filter(v => !!v)
              .map(item => {
                let tag = {};
                if (this.dict.length > 0) {
                  tag = this.dict.find(v => v.value == item);
                }
                let timeSpace = ["119051", "118703", "118702"];
                if (item - 120700 > 0 && item - 120700 < 21) {
                  timeSpace.push(item);
                }
                return (
                  <div className="label">
                    {timeSpace.includes(tag.value) ? (
                      <>
                        <span className="label-palce-icon">
                          <IconFont type={"icon-S_Bar_Eye"} theme="outlined" />{" "}
                        </span>
                        {tag.label}
                      </>
                    ) : (
                      tag.label
                    )}
                  </div>
                );
              })}
            {tags.length > 4 && <span className="label-d">...</span>}
          </div>
        ) : (
          <div className="footer-none">无标签</div>
        )}
      </div>
    );
  }
}

export default ObjectMapPersonnelCard;
