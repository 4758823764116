import React, { Component } from 'react';
import './index.less';
/**
 * @param [String] className 自定义类名
 * @param [String] imageType 图片类型（宽高类型）face 人脸  door 门禁 car 机动车
 * @param [Function] onClick 图片点击方法
 * @param [string] url 图片地址
 * @param [string] plate 车牌
 * @param [string] name 姓名
 * @param [string] address 地址
 * @param [string] deviceAddress 设备地址
 * @param [string] time 时间
 * @param [string] access 进出类型
 * @param [string] controlType 门禁卡类型
 * @param [string] deviceName 设备名称
 */

/**
 * 门禁  姓名 门禁卡类型 门禁位置 时间
 *
 * 车辆  车牌 姓名 设备地址 时间 进出类型
 *
 * 人体  设备地址 时间
 *
 * 人脸  姓名 地址 设备名称 时间
 *
 */
const IconFont = Loader.loadBaseComponent('IconFont');
const ImageView = Loader.loadBaseComponent('ImageView');

class LeftRightCard extends Component {
  getText = parms => {
    let icon = '';
    switch (parms) {
      case 'plate':
        icon = 'icon-S_Bar_Brand';
        break;
      case 'name':
        icon = 'icon-S_Tree_People';
        break;
      case 'controlType':
        icon = 'icon-S_AID_DoorType';
        break;
      case 'address':
        icon = 'icon-S_Bar_Map';
        break;
      case 'deviceName':
        icon = 'icon-S_Bar_Add';
        break;
      case 'time':
        icon = 'icon-S_Edit_ClockStart';
        break;
      case 'access':
        icon = 'icon-S_Arrow_AllocationYes';
        break;
      default:
        break;
    }
    let text = parms === 'time' ? Utils.formatTimeStamp(this.props[parms]) : this.props[parms];
    let className = parms === 'plate' ? 'yellow' : '';
    if(parms === 'name' && !!this.props.aid) {
      text = this.props.aid;
      className = 'error';
    }
    return this.props[parms] ? (
      <div className="info" title={text}>
        <IconFont className="info-icon" type={icon} theme="outlined" />
        <span className={className}>{text}</span>
      </div>
    ) : (
      ''
    );
  };

  render() {
    const { className = '', imageType = 'face', url = '', temporary } = this.props;
    return (
      <div className={`cloud-card-view ${className} card-${imageType}`}>
        <div className="card-left">
          <ImageView src={url} hasErrorImageStyle={false} />
        </div>
        <div className="card-right">
          {temporary && (
            <div className="info" title="临时车辆">
              <IconFont className="info-icon" type="icon-L_AID_Motor" theme="outlined" />
              <span className='error'>临时车辆</span>
            </div>
          )}
          {['plate', 'name', 'controlType', 'deviceName', 'address', 'time', 'access'].map(v => this.getText(v))}
        </div>
      </div>
    );
  }
}

export default LeftRightCard;
