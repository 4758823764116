import React, { Component } from 'react'
import { Card ,Button } from 'antd'
import moment from 'moment';
import wifiIcon from './wifiIcon.svg'
import './index.less'
const IconSpan = Loader.loadComponent('IconSpan')
class WifiCard extends Component {
  //计算卡片的日历时间，显示**天、**时  或者是**时、**分
  accountTime = (startTime, endTime) => {
    let nd = 1000 * 24 * 60 * 60;
    let nh = 1000 * 60 * 60;
    let nm = 1000 * 60;
    let diff = endTime - startTime;
    // 计算差多少天
    let days = Math.floor(diff / nd);
    // 计算差多少小时
    let hours = Math.floor(diff % nd / nh);
    // 计算差多少分钟
    let mins = Math.floor(diff % nd % nh / nm);
    if(days > 0){
      return {
        onlineTime: <p><span>{ days }</span> <span>天</span></p>,
        offlineTime: <p><span>{ hours }</span><span>时</span></p>
      }
    }else{
      return {
        onlineTime: <p><span>{ hours }</span><span>时</span></p>,
        offlineTime: <p><span>{ mins }</span><span>分</span></p>
      }
    }
  }

  render(){
    const { item={}, locationDetail } = this.props
    const v = item
    let timeObj = null
    if(v.endTime){
      timeObj = this.accountTime(v.startTime, v.endTime)
    }
    return (
      <Card className='wifi-cards'>
        <div className="wifi-cards-top">
        {
          v.endTime ? (
            <span className="wificards-kalendar">
              <Button className="wifi-kalendar-top">{timeObj.onlineTime}</Button>
              <Button className="wifi-kalendar-bottom">{timeObj.offlineTime}</Button>
            </span>
          ):(
            <span className="wificards-kalendar">
              <img src={wifiIcon}/>
            </span>
          )
        }
          <span className="wificards-device-name">
            {/* <span>设备MAC: </span> */}
            <b className='device-mac'>{v.deviceMac}</b>
            <Button onClick = {() => locationDetail({address: v.address,captureDeviceName:v.captureDeviceName,longitude:Number(v.longitude),latitude: Number(v.latitude)})}>
              <IconSpan icon="icon-S_Bar_Add"/>
              位置详情
            </Button>
          </span>
        </div>
        <div className="wifi-cards-middle">
          <div className='item-title'>时间:</div>
          <span>
            <IconSpan icon="icon-M_Bar_Clock" className="wifi-time-online"/>
            <span>发现：<span>{v.startTime? moment(+v.startTime).format('YYYY-MM-DD HH:mm:ss'): '--'}</span></span>
          </span>
          <span>
            <IconSpan icon="icon-M_Bar_Clock"/>
            <span>离开：{v.endTime?<span>{moment(+v.endTime).format('YYYY-MM-DD HH:mm:ss')}</span>:'--'}</span>
          </span>
        </div>
        <div className="wifi-cards-bottom">
          <div className='item-title'>采集设备:</div>
          <span>
            <IconSpan icon="icon-M_Bar_ResetAdd"/>
            <span>经纬度：<span>{`${v.longitude}-${v.latitude}`}</span></span>
          </span>
          <span>
            <IconSpan icon="icon-S_View_Task"/>
            <span>名称：<span title={`${v.captureDeviceName}`}>{v.captureDeviceName}</span></span>
          </span>
          <span>
            <IconSpan icon="icon-S_Tree_Community"/>
            <span>MAC : <span>{v.captureDeviceMac}</span></span>
          </span>
          <span>
            <IconSpan icon="icon-S_Bar_Map"/>
            <span className="wifi-list-address">位置：<span title={`${v.address}`}>{v.address || '--'}</span></span>
          </span>
        </div>
      </Card>
    )
  }
}
export default WifiCard