import React, { Component } from "react";
import "./index.less";
import { Tooltip } from "antd";

const ItemCard = Loader.loadBusinessComponent("Card", "CaptureCard");
const IconFont = Loader.loadBaseComponent("IconFont");
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");

@Decorator.businessProvider("tab")
class AccessControlCard extends Component {
  /**
   * @desc 跳转到人员档案
   */
  goPersonDetail = e => {
    e.stopPropagation();
    const { item, tab, location } = this.props;
    const { personId } = item;
    Service.person.queryPersonBindInfo({ personId, aid: "" }).then(res => {
      let itemData = res.data || {};
      const { hasAid } = itemData;
      let moduleName = "objectMapPersonnelDetail",
        type = "";
      if (!!hasAid) {
        type = "ploy";
      } else {
        type = "entry";
      }
      tab.openDetailPage({
        moduleName,
        value: personId,
        data: { id: personId },
        beforeAction: async () => {
          await window.LM_DB.add("parameter", { id: personId, url: item.personInfoUrl, type });
        }
      });
    });
  };

  prevChildren = () => {
    const { item, searchBtn = false } = this.props;
    const { personId } = item;
    let accessTypeItem = Dict.map.openType.find(v => v.value === item.accessType);
    let accessType = "其他";
    if (accessTypeItem) {
      accessType = accessTypeItem.label;
    }
    let hasAction = BaseStore.menu.getInfoByName("objectMapPersonnel"); //获取按钮权限
    return (
      <>
        <div className="item person-name">
          <IconFont type="icon-S_Tree_People" />
          {item.personName || "-"}
        </div>
        <div className="item">
          <IconFont type="icon-S_AID_DoorType" />
          {accessType}
        </div>
        <div className="item">
          <IconFont type="icon-S_AID_DoorNum" />
          {item.accessCardNo || "-"}
        </div>
        {searchBtn && personId && hasAction && (
          <div className="search-btn-group" onClick={e => e.stopPropagation()}>
            <AuthComponent actionName="objectMapPersonnel">
              <Tooltip title="人员档案" onClick={this.goPersonDetail}>
                <IconFont type="icon-S_Bar_NameCard" />
              </Tooltip>
            </AuthComponent>
          </div>
        )}
      </>
    );
  };
  render() {
    const { item, size, className, hoverScale = true, active = false, onClick } = this.props;
    return (
      <ItemCard
        className={`access-control access-control-card-${size} ${className}`}
        onClick={onClick}
        imgUrl={item.pictureUrl}
        prevChildren={this.prevChildren()}
        address={item.address}
        captureTime={item.captureTime}
        relativeIcon={false}
        hoverScale={hoverScale}
        active={active}
      />
    );
  }
}

export default AccessControlCard;
