import React from "react";
import { Checkbox, Progress } from "antd";
import "./index.less";

const CaptureCard = Loader.loadBusinessComponent("Card", "CaptureCard");

class Similar extends React.Component {
  constructor(props) {
    super(props);
  }
  onChange = e => {
    const checked = e.target.checked;
    this.props.onClick && this.props.onClick(checked, this.props.data);
  };
  render() {
    const { data = {}, imgUrl } = this.props;
    return (
      <CaptureCard
        className="similar-card"
        hoverScale={false}
        imgUrl={imgUrl}
        score={data.score}
        checkboxItem={
          <Checkbox checked={data.checked} onChange={this.onChange} />
        }
      />
    );
  }
}

export default Similar;
