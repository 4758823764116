import React from "react";
import "./index.less";
const CommunityResourceChart = Loader.loadBusinessComponent(
  "Statistics",
  "CommunityResourceChart"
);
export default class CommunityChartCard extends React.Component {
  render() {
    const { title, myColor, data, filterData,resource,startRadius,endRadius,className=''} = this.props;
    return (
      <div className={`community-chart ${className}`}>
        <div className="chart-title">{title}</div>
        <div className="chart-mes-show">
            <CommunityResourceChart
              title={title}
              myColor={myColor}
              startRadius={startRadius}
              endRadius={endRadius}
              data={data}
              filterData={filterData}
              resource={resource}
            />
        </div>
      </div>
    );
  }
}
