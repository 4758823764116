import React, { Component } from 'react';
import { Progress } from 'antd';
import './index.less';
const ImageView = Loader.loadBaseComponent('ImageView');
const IconFont = Loader.loadBaseComponent('IconFont');
/**
 * @desc 基础卡片
 * @param {string} className 类名
 * @param {boolean} hoverScale img是否hover放大
 * @param {string} name 姓名
 * @param {string} score 相似度
 * @param {string} plateNo 车牌号码
 * @param {string} imgUrl 图片url
 * @param {string} deviceName 设备名称
 * @param {string} captureTime 抓拍时间
 * @param {string} relativeIcon 关联图标
 * @param {*} renderItem 自定义渲染函数
 * @param {*} CheckboxItem checkout
 * @param {function} onClick 点击回调函数
 */
class CaptureCard extends Component {
  render() {
    let {
      className = '',
      active = false,
      score,
      plateNo,
      deviceName,
      address,
      hoverScale = true,
      captureTime,
      imgUrl = '',
      relativeIcon = 'icon-L_AID_Body',
      renderItem = undefined,
      checkboxItem = undefined,
      onClick,
      children,
      name,
      prevChildren
    } = this.props;
    if (score) {
      if (score >= 100) {
        score = 99;
      } else {
        score = score.toString().substring(0, 2);
      }
    }
    return (
      <div className={`capture-card-item ${className} ${active ? 'active' : ''}`} onClick={onClick}>
        <div className={`item-img-box ${hoverScale && imgUrl ? 'img-hover-scale' : ''}`}>
          <ImageView src={imgUrl} />
          {relativeIcon && <IconFont className="relative-icon" type={relativeIcon} />}
          {checkboxItem}
        </div>
        <div className="item-info">
          {prevChildren}
          {(score || score === 0) && (
            <div className="item">
              <IconFont type="icon-S_Photo_Like" />
              <span className="process alarm-score-progress">
                相似度
                <Progress percent={score} size="small" status="active" showInfo={false} strokeWidth={2} />
                <span style={{ fontSize: '16px' }} className="highlight">
                  {score}%
                </span>
              </span>
            </div>
          )}
          {name && (
            <div className="item plate-number">
              <IconFont type="icon-M_Login_UserName" />
              {name}
            </div>
          )}
          {plateNo && (
            <div className="item plate-number">
              <IconFont type="icon-S_Bar_Brand" />
              {plateNo}
            </div>
          )}
          {(deviceName || address) && (
            <div className="item device-name" title={deviceName || address}>
              <IconFont type="icon-S_Bar_Add" />
              {(deviceName && Utils.getSubStr(deviceName)) || (address && Utils.getSubStr(address))}
            </div>
          )}
          {captureTime && (
            <div className="item capture-time">
              <IconFont type="icon-S_Edit_ClockEnd" />
              {Utils.formatTimeStamp(captureTime)}
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}
export default CaptureCard;
