import React, { Component } from 'react';
import { Checkbox, Tooltip } from 'antd';
import './index.less';

const IconFont = Loader.loadBaseComponent('IconFont');
const ImageView = Loader.loadBaseComponent('ImageView');
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");
const scrDefault = '/resource/image/user-default.svg';
const imageError = '/resource/image/imageError.svg';

export default class MassControlCard extends Component {
  handleDetle = (data, event) => {
    Utils.stopPropagation(event);
    const { handleDetle } = this.props;
    handleDetle && handleDetle(data);
  };
  clickCard = event => {
    Utils.stopPropagation(event);
    const { onClick, data } = this.props;
    onClick && onClick(data);
  };
  render() {
    const { data = {}, type = 0, activeId } = this.props;
    const { uploadTime, address, content, uploadUserId, attachments = [], userAvatar, realName } = data;
    let src = '';
    if (attachments.length) {
      if (attachments[0].type === '2') {
        src = attachments[0].videoThumbnailUrl;
      } else {
        src = attachments[0].url;
      }
    }
    return (
      <div className={`mass-control-card ${activeId === data.id ? 'active' : ''}`} onClick={this.clickCard}>
        <div className={`mass-control-card-checkbox ${type === 0 ? '' : 'checkbox-active'}`}>
          <Checkbox value={data} />
        </div>
        <div className={`mass-control-card-content ${type === 0 ? '' : 'content-active'}`}>
          <div className="card-content-header">
            <div className="header-info">
              <div className="header-info-img-box">
                <ImageView className="info-img" defaultSrc={scrDefault} src={userAvatar} hasErrorImageStyle={false} />
              </div>
              <div className="info">
                <div className="info-name">{realName || ''}</div>
                <div className="info-text">{uploadTime ? Utils.formatTimeStamp(uploadTime) : ''}</div>
              </div>
            </div>
            <AuthComponent actionName="masscontrolAdministration">
              <Tooltip title="删除线索">
                  <IconFont type="icon-S_Edit_Delete" onClick={event => this.handleDetle(data, event)} theme="outlined" />
              </Tooltip>
            </AuthComponent>
          </div>
          <div className="card-content-section">
            <div className="section-img-box">
              <ImageView className="section-img" src={src} hasErrorImageStyle={false} />
            </div>
            <div className="section-info">
              <div className="section-info-title ellipsis" title="">
                {content || ''}
              </div>
              <div className="detail-info ellipsis" title="">
                {address || ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
