import React from 'react';
import moment from 'moment';
import './index.less';

const IconFont = Loader.loadBaseComponent('IconFont');
const CaptureCard = Loader.loadBusinessComponent('Card', 'CaptureCard');
const AuthComponent = Loader.loadBusinessComponent('AuthComponent');

class ForeignCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data = {}, isActual, handleChangeYN, handelAuthName, className, handleJumPage, hasDuration = true } = this.props;
    let { imageUrl, alarmTime, violationStatus, isHandle, isEffective, taskName, deviceName, id, height, duration, cameraName } = data;
    return (
      <CaptureCard
        className={`${isActual ? 'control-card-real' : 'control-card'} ${className}`}
        style={{ height: `${height}px!important` }}
        onClick={() => handleJumPage && handleJumPage(id, data)}
        hoverScale={false}
        imgUrl={imageUrl}
        relativeIcon={''}
        captureTime={alarmTime}
        deviceName={deviceName || cameraName}
      >
        {hasDuration && (
          <div className="item">
            <IconFont type={'icon-M_AID_TimeSpace'} theme="outlined" />
            <span className="content-value" title={duration}>
              {duration ? Utils.formatDurationTime(+duration) : '-'}
            </span>
          </div>
        )}
        <div className="item">
          <IconFont type={'icon-S_Bar_Layer'} theme="outlined" />
          <span className="content-value" title={taskName}>
            {taskName || '-'}
          </span>
        </div>
        {violationStatus === -1 || isHandle === 0 ? (
          <div className="card-footer">
            <AuthComponent actionName={handelAuthName}>
              <div className="handle handle-no" onClick={handleChangeYN && handleChangeYN.bind(this, data, 1)}>
                <IconFont type={'icon-S_Photo_ThumbInvalid'} theme="outlined" />
                已违规
              </div>
            </AuthComponent>
            <AuthComponent actionName={handelAuthName}>
              <div className="handle handle-yes" onClick={handleChangeYN && handleChangeYN.bind(this, data, 0)}>
                <IconFont type={'icon-S_Photo_ThumbEffective'} theme="outlined" />
                未违规
              </div>
            </AuthComponent>
          </div>
        ) : (
          <div className="card-footer" style={{ height: '24px' }} />
        )}
        {((violationStatus !== undefined && violationStatus !== -1) || (isHandle !== undefined && isHandle !== 0)) && (
          <div className={`alam-rotate ${violationStatus == 1 || isEffective == 1 ? 'alarm-rotate-yes' : 'alarm-rotate-no'}`} />
        )}
      </CaptureCard>
    );
  }
}

export default ForeignCard;
